import React from "react"
import "../style/about.css"
import Layout from "../components/layout"

class KeyPlan extends React.Component {  
    render() {
        return (
            <Layout>
                <br /><br /><br /><br /> 
                    <div className="row pt-5 pb-5">
                        <div className="col-md-12">
                            <h1 className="text-center text-aspirered">Key Plan</h1>
                            <hr className="kp" />
                        </div>
                        <div className="col-md-12" >
                        <iframe className="ikp" src="https://storage.googleapis.com/www.realvaluehomes.in/Key%20Plan/Aspire_Aurum_Key_Plan.html" height="100vh" width="100%" title="Key Plan"></iframe>
                        </div>
                    </div>
		<script src="https://www.kenyt.ai/botapp/ChatbotUI/dist/js/bot-loader.js" type="text/javascript" data-bot="25390341"></script>
            </Layout>
        )
    }
}
export default KeyPlan
